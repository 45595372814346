import React from "react";



function Dashboard() {

  return (
    <div className="App">
        <h1 className="text-center">PlawoXYZ - Admin</h1>
        
    </div>
  );
}

export default Dashboard;
