import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { NavLink, Route, Routes, Link } from "react-router-dom";

import "./assets/css/App.css";

import { db, auth } from "./services/firebase";

import BTW from "./views/BTW";
import BW from "./views/BW";
import Lawo from "./views/Lawo";
import Quest from "./views/questlog/Quest";
import SWG from "./views/swg/Swg";
import SWGADMIN from "./views/swg/Swg-admin";
import Home from "./views/Home";
import Proost from "./views/proost/Proost";
import Race from "./views/race/Race";
import ProostPreGame from "./views/proost/ProostPreGame";
import Calculator from "./views/calculator/Calculator";
import Questions from "./views/Questions";
import AdminDashboard from "./views/admin/Dashboard";
import CardsAgainstHumanity from "./views/cardsAgainstHumanity/CardsAgainstHumanity";
import CardsAgainstHumanityPreGame from "./views/cardsAgainstHumanity/CardsAgainstHumanityPreGame";

import Riddler from "./views/riddler/Riddler";
import Spy from "./views/spy/Spy";
import Battleshots from "./views/battleshots/Battleshots";
import BattleshotsPreGame from "./views/battleshots/BattleshotsPreGame";
import F1Simulator from "./views/f1simulator/f1simulator";
import SecretSanta from "./views/secretSanta/secretSanta";
import Fridge from "./views/fridge/Fridge";
import Swg from "./views/swg/Swg";
// import UFSO from "./views/UFSO/Home";

// import SteamChecker from "./views/tools/SteamChecker/Steamchecker";

const App = () => {
  const [settings, setsettings] = useState({});
  const [userData, setUserData] = useState({});
  const [userAuth, setUserAuth] = useState({});

  auth().onAuthStateChanged(function (user) {
    if (user) {
      setUserAuth(user);
    } else {
      console.log("NO USER");
    }
  });

  auth()
    .signInAnonymously()
    .then(() => {
      console.log("SIGNED IN");
    })
    .catch((error) => {
      console.warn("error", error);
      // ...
    });

  // const fakeAuth = {
  //   isAuthenticated: false,
  //   authenticate(cb) {
  //     this.isAuthenticated = true;
  //     setTimeout(cb, 100); // fake async
  //   },
  //   signout(cb) {
  //     this.isAuthenticated = false;
  //     setTimeout(cb, 100); // fake async
  //   },
  // };

  // const PrivateRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       fakeAuth.isAuthenticated === true ? (
  //         <Component {...props} />
  //       ) : (
  //         <Navigate to='/' />
  //       )
  //     }
  //   />
  // );

  useEffect(() => {
    db.ref("settings").on("value", (snapshot) => {
      if (snapshot.val()) {
        setsettings(snapshot.val());
      }
    });
  }, []);

  // useEffect(() => {
  //   fetch(
  //     "https://ipgeolocation.abstractapi.com/v1/?api_key=3002b22eabc94745a96c3b298ab6af82"
  //   )
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         setUserData(result);
  //       },
  //       // Note: it's important to handle errors here
  //       // instead of a catch() block so that we don't swallow
  //       // exceptions from actual bugs in components.
  //       (error) => {
  //         this.setState({
  //           isLoaded: true,
  //           error,
  //         });
  //       }
  //     );
  // }, []);

  const Navbar = () => {
    return (
      <nav className='navbar navbar-expand-lg navbar-dark bg-dark justify-content-between'>
        <a className='navbar-brand' href='/'>
          PlawoXYZ
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div className='collapse navbar-collapse ' id='navbarSupportedContent'>
          <ul className='navbar-nav mr-auto'>
            <li className='nav-item'>
              <Link className='nav-link' to='/'>
                Hjem
              </Link>
            </li>
            {/* <li className='nav-item'>
              <NavLink
                activeclassname='active'
                className='nav-link disabled'
                to='/questions'
              >
                100 Spørsmål
              </NavLink>
            </li> */}
            {/* <li className='nav-item'>
              <NavLink
                activeclassname='active'
                className='nav-link'
                to='/quest'
              >
                Questlog <span className='badge bg-primary'>Beta</span>
              </NavLink>
            </li> */}
            <li className='nav-item'>
              <NavLink
                activeclassname='active'
                className='nav-link disable'
                to='/proost'
              >
                Proost <span className='badge bg-primary'>Beta</span>
              </NavLink>
            </li>
            {/* <li className='nav-item'>
              <NavLink
                activeclassname='active'
                className='nav-link disabled'
                to='/cah'
              >
                CAH <span className='badge bg-primary'>Beta</span>
              </NavLink>
            </li> */}
            {/* <li className='nav-item'>
              <NavLink
                activeclassname='active'
                className='nav-link disabled'
                to='/battleshots'
              >
                Battleshots
              </NavLink>
            </li> */}
            {/* <li className='nav-item'>
              <NavLink
                activeclassname='active'
                className='nav-link'
                to='/swg'
              >
                SWG
              </NavLink>
            </li> */}
            <li className='nav-item'>
              <NavLink
                activeclassname='active'
                className='nav-link'
                to='/fridge'
              >
                Fridge
              </NavLink>
            </li>
            {/* <li className='nav-item'>
              <NavLink
                activeclassname='active'
                className='nav-link disabled'
                to='/spy'
              >
                Spy
              </NavLink>
            </li> */}
            {/* <li className='nav-item'>
              <NavLink
                activeclassname='active'
                className='nav-link disabled'
                to='/riddler'
              >
                Riddler
              </NavLink>
            </li> */}
          </ul>
        </div>

        <div className='white'>ID : {userAuth.uid}</div>
      </nav>
    );
  };

  return (
    <div>
      <Navbar />
      <div className='mainContent'>
        <Routes>
          <Route path='/bw' element={<BW />}></Route>
          <Route path='/bw' element={<BTW />}></Route>
          <Route path='/Lawo' element={<Lawo />}></Route>
          <Route path='/Swg' element={<SWG />}></Route>
          <Route path='/Swg/admin' element={<SWGADMIN />}></Route>
          <Route path='/race' element={<Race />}></Route>
          <Route path='/f1' element={<F1Simulator />}></Route>
          <Route
            path='/secretSanta'
            element={
              <SecretSanta
                userData={userData}
                userAuth={userAuth}
                globalSettings={settings}
              />
            }
          ></Route>

          <Route
            exact
            path='/questions'
            element={
              <Questions
                userData={userData}
                userAuth={userAuth}
                globalSettings={settings}
              />
            }
          ></Route>
          <Route
            exact
            path='/riddler'
            element={
              <Riddler
                userData={userData}
                userAuth={userAuth}
                globalSettings={settings}
              />
            }
          ></Route>
          <Route
            exact
            path='/quest/:id'
            element={
              <Quest
                userData={userData}
                userAuth={userAuth}
                globalSettings={settings}
              />
            }
          ></Route>
          <Route
            exact
            path='/fridge'
            element={
              <Fridge
                userData={userData}
                userAuth={userAuth}
                globalSettings={settings}
              />
            }
          ></Route>
          <Route
            exact
            path='/spy'
            element={
              <Spy
                userData={userData}
                userAuth={userAuth}
                globalSettings={settings}
              />
            }
          ></Route>
          <Route
            exact
            path='/battleshots/:id'
            element={
              <Battleshots
                userData={userData}
                userAuth={userAuth}
                globalSettings={settings}
              />
            }
          ></Route>
          <Route
            exact
            path='/proost/:id'
            element={
              <Proost
                userData={userData}
                userAuth={userAuth}
                globalSettings={settings}
              />
            }
          ></Route>
          <Route
            exact
            path='/proost'
            element={
              <ProostPreGame
                userData={userData}
                userAuth={userAuth}
                globalSettings={settings}
              />
            }
          ></Route>
          <Route
            exact
            path='/calculator'
            element={
              <Calculator
                userData={userData}
                userAuth={userAuth}
                globalSettings={settings}
              />
            }
          ></Route>
          <Route
            exact
            path='/battleshots'
            element={
              <BattleshotsPreGame
                userData={userData}
                userAuth={userAuth}
                globalSettings={settings}
              />
            }
          ></Route>
          <Route
            exact
            path='/cah/:id'
            element={
              <CardsAgainstHumanity
                userData={userData}
                userAuth={userAuth}
                globalSettings={settings}
              />
            }
          ></Route>
          <Route
            exact
            path='/cah'
            element={
              <CardsAgainstHumanityPreGame
                userAuth={userAuth}
                userData={userData}
                globalSettings={settings}
              />
            }
          ></Route>
          <Route exact path='/admin' elemenet={<AdminDashboard />}></Route>
          {/* <PrivateRoute path='/ufso' component={UFSO}></PrivateRoute> */}
          {/* <Route exact path="/tools">
              <SteamChecker />
            </Route> */}
          <Route exact path='/' element={<Home />}></Route>
        </Routes>
      </div>
    </div>
  );
};

export default App;
