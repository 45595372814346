import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { db } from "../../services/firebase";

import "./battleshots.css";

const Cell = (col, row) => {
  return (
    <span className="cell">
      {col} {"-------"} {row}
    </span>
  );
};

const Battlegrid = (ships) => {
  let rows = 10;
  let cols = 10;

  const grid = [];
  for (let row = 0; row < rows; row++) {
    const currentRow = [];
    for (let col = 0; col < cols; col++) {
      let newNode = {
        row: row,
        col: col,
      };
      currentRow.push(newNode);
    }
    grid.push(currentRow);
  }

  console.log("grid", grid);

  return (
    <>
      {grid.map((subArray, index) => {
        return (
          <div key={index} className="col no-padding">
            {subArray.map((subitem, i) => {
              console.log(subitem);
              return (
                <div className="cell col">
                  {subitem.row},{subitem.col}
                </div>
              );
            })}
          </div>
        );
      })}
      {/* {grid.map((rows, index) => {
        {rows}
        rows.map((cols, index) => {
          <span className="cell">
            {cols.col}, {cols.row}
          </span>;
        });
      })} */}
    </>
  );
};

const Battleshots = (props) => {
  const { userData, userAuth } = props;
  const { id } = useParams();

  console.log("userData", userData);
  console.log("userAuth", userAuth);

  const rootRef = "battleshots/" + id + "/";

  const [gameSettings, setgameSettings] = useState([]);
  const [players, setPlayers] = useState([]);

  console.log("players", players);
  useEffect(() => {
    db.ref(rootRef + "settings").on("value", (snapshot) => {
      if (snapshot.val()) {
        setgameSettings(snapshot.val());
      }
    });
  }, [id]);

  useEffect(() => {
    db.ref(rootRef + "players").on("value", (snapshot) => {
      if (snapshot.val()) {
        const sampleArray = _.toArray(snapshot.val());
        setPlayers(sampleArray);
      }
    });
  }, [rootRef]);

  const changeEntrySetting = (e) => {
    let updates = {};

    if (e.target.name === "allow_entries") {
      updates[rootRef + "settings/allow_entries"] = !gameSettings.allow_entries;
    }

    if (e.target.name === "allow_new_players") {
      updates[
        rootRef + "settings/allow_new_players"
      ] = !gameSettings.allow_new_players;
    }

    if (e.target.name === "allow_screen_tap") {
      updates[
        rootRef + "settings/allow_screen_tap"
      ] = !gameSettings.allow_screen_tap;
    }

    return db.ref().update(updates);
  };

  return (
    <div className="App">
      <div className="text-center">This is a new component, Battleshots</div>
      {userData.ip_address === gameSettings.created_by ? (
        <div className="row container">
          <span className="col-12">
            <h4>Instillinger</h4>
          </span>

          <span className="col-4">
            Brukere kan stille spørsmål
            <label className="switch">
              <input
                name="allow_entries"
                checked={gameSettings.allow_entries}
                onChange={changeEntrySetting}
                type="checkbox"
              ></input>
              <span className="slider round"></span>
            </label>
          </span>
          <span className="col-4">
            Brukere kan legge til spillere
            <label className="switch">
              <input
                name="allow_new_players"
                checked={gameSettings.allow_new_players}
                onChange={changeEntrySetting}
                type="checkbox"
              ></input>
              <span className="slider round"></span>
            </label>
          </span>
          <span className="col-4">
            Brukere kan bytte spørsmål
            <label className="switch">
              <input
                name="allow_screen_tap"
                checked={gameSettings.allow_screen_tap}
                onChange={changeEntrySetting}
                type="checkbox"
              ></input>
              <span className="slider round"></span>
            </label>
          </span>
        </div>
      ) : null}
      <div className="row container">
        <span className="col-12">
          <h4>
            Romkode: {gameSettings.name}
            {gameSettings.password ? (
              <span> / Passord: {gameSettings.password}</span>
            ) : null}
          </h4>
        </span>
      </div>
      <div className="container">
        <div id="play-area">
          <div className="flex-grid">
            <Battlegrid></Battlegrid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Battleshots;
