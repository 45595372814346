import React from "react";

import "./race.css";

const Race = (props) => {
  // const {globalSettings, userData} = props;

  let race_distance = 100;
  let current_distance = 0;

  let car = {
    number: 1,
    name: "test",
    score: 76,
    distance_traveled: 0,
    mistake_rate: 2,
  };

  const simulateSecond = () => {
    let distance_to_travel = 1;
    let modifier_value;
    current_distance++;
    if (car.score) {
      modifier_value = car.score / 100;
    }

    distance_to_travel = distance_to_travel + modifier_value;

    console.log("modifier_value", modifier_value);

    car.distance_traveled = car.distance_traveled + distance_to_travel;

    console.log("current_distance", current_distance);
    console.log("car", car);
  };

  return (
    <div className='App'>
      <div class='text-center'>
        <div className='row'>
          <div className='col-12'>
            <span>
              <h1 style={{ display: "inline-block" }}>Race</h1>
            </span>
            <div>
              <input
                className='form-control'
                type='button'
                value='Change'
                onClick={(e) => simulateSecond()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Race;
