import React, { useState, useEffect } from "react";
import _ from "lodash";

import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { db } from "../../services/firebase";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faCheck,
  //   faExclamation,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

import "./quest.css";

function Quest(props) {
  const { userAuth } = props;
  const { id } = useParams();

  let history = useNavigate();

  const rootRef = "questlog/" + id + "/";

  const [questslog, setQuestlog] = useState([]);
  const [gameDetails, setGameDetails] = useState([]);
  const [gameSettings, setgameSettings] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [reward, setReward] = useState("");

  useEffect(() => {
    db.ref("questlog/" + id).on("value", (snapshot) => {
      if (snapshot.val()) {
        setGameDetails(snapshot.val());
        console.log("GAME FOUND");
      } else {
        console.warn("NO GAME FOUND");
      }
    });
  }, [history, rootRef]);

  useEffect(() => {
    db.ref(rootRef + "quests").on("value", (snapshot) => {
      if (snapshot.val()) {
        const sampleArray = _.toArray(snapshot.val());
        setQuestlog(sampleArray);
        console.log(sampleArray);
      }
    });
  }, [rootRef]);

  useEffect(() => {
    db.ref(rootRef + "/settings").on("value", (snapshot) => {
      if (snapshot.val()) {
        setgameSettings(snapshot.val());
      }
    });
  }, [id]);

  const resetState = () => {
    setTitle("");
    setDescription("");
    setReward("");
  };

  const addNewQuestion = () => {
    // Get a key for a new Post.
    let newQuestionKey = db
      .ref()
      .child("questlog/" + id + "/quest")
      .push().key;

    // A post entry.
    let postData = {
      title: title,
      visible: false,
      completed: false,
      failed: false,
      description: description,
      reward: reward,
      id: newQuestionKey,
    };

    // Write the new post's data simultaneously in the posts list and the user's post list.
    let updates = {};
    updates[rootRef + "quests/" + newQuestionKey] = postData;

    resetState();
    return db.ref().update(updates);
  };

  const changeEntrySetting = (e, quest) => {
    let updates = {};

    if (e.target.name === "quest_completed") {
      updates[rootRef + "quests/" + quest.id + "/completed"] = !quest.completed;
    }

    if (e.target.name === "quest_visible") {
      updates[rootRef + "quests/" + quest.id + "/visible"] = !quest.visible;
    }

    if (e.target.name === "quest_failed") {
      updates[rootRef + "quests/" + quest.id + "/failed"] = !quest.failed;
    }

    return db.ref().update(updates);
  };

  const showQuest = (quest, i) => {
    var questClasses = classNames({
      quest: true,
      "quest-container-failed": quest.failed,
      "quest-container-completed": quest.completed,
    });

    if (!quest.visible && userAuth.uid !== gameSettings.created_by) {
      return;
    }
    return (
      <div key={i} className={questClasses}>
        <div className='row'>
          <div className='col-11'>
            <h2>{quest.title}</h2>
          </div>
          <div className='col-1 icon'>
            {quest.failed ? (
              <FontAwesomeIcon className='icon quest-failed' icon={faX} />
            ) : null}
            {quest.completed ? (
              <FontAwesomeIcon
                className='icon quest-completed'
                icon={faCheck}
              />
            ) : null}
            {!quest.completed && !quest.failed ? (
              <FontAwesomeIcon className='icon golden' icon={faQuestion} beat />
            ) : null}
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <p>{quest.description}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <p>Reward : {quest.reward ? quest.reward : "No reward"}</p>
          </div>
        </div>
        <div className=''>
          {quest.failed ? (
            <div className='quest-failed'>
              <p>Quest failed...</p>
            </div>
          ) : null}
          {quest.completed ? (
            <div className='quest-completed'>
              <p>Quest completed!</p>
            </div>
          ) : null}
        </div>

        <div className='col-12'>
          {userAuth.uid === gameSettings.created_by ? (
            <div className='row'>
              <div className='col-4'>
                Quest visible
                <label className='switch'>
                  <input
                    name='quest_visible'
                    checked={quest.visible}
                    onChange={(e) => changeEntrySetting(e, quest)}
                    type='checkbox'
                  ></input>
                  <span className='slider round'></span>
                </label>
              </div>
              <div className='col-4'>
                Quest completed
                <label className='switch'>
                  <input
                    name='quest_completed'
                    checked={quest.completed}
                    onChange={(e) => changeEntrySetting(e, quest)}
                    type='checkbox'
                  ></input>
                  <span className='slider round'></span>
                </label>
              </div>
              <div className='col-4'>
                Quest failed
                <label className='switch'>
                  <input
                    name='quest_failed'
                    checked={quest.failed}
                    onChange={(e) => changeEntrySetting(e, quest)}
                    type='checkbox'
                  ></input>
                  <span className='slider round'></span>
                </label>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div className='App questLog'>
      <section>
        {userAuth.uid === gameSettings.created_by ? (
          <div className='container'>
            <span className='col-12'>
              <h4>Settings</h4>
              Roomcode: {id}
            </span>
            <div className='add-quest container'>
              <div className='row'>
                <div className='col-6'>
                  <div className='form-group'>
                    <label htmlFor='exampleFormControlInput1'>
                      Quest title
                    </label>
                    <div className='input-group'>
                      <input
                        className='form-control'
                        type='text'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='form-group'>
                    <label htmlFor='exampleFormControlInput1'>
                      Quest reward
                    </label>
                    <div className='input-group'>
                      <input
                        className='form-control'
                        type='text'
                        value={reward}
                        onChange={(e) => setReward(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='form-group'>
                    <label htmlFor='exampleFormControlInput1'>
                      Quest description
                    </label>
                    <div className='input-group'>
                      <textarea
                        className='form-control'
                        type='text'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <button
                    className='btn btn-primary btn-block'
                    onClick={addNewQuestion}
                  >
                    Legg til
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
      <section className='container'>
        {gameDetails.name ? (
          <div>
            <h1>Questlog for {gameDetails.name}</h1>
          </div>
        ) : (
          <div>No game found</div>
        )}

        {questslog.map((quest, i) => showQuest(quest, i))}
      </section>
    </div>
  );
}

export default Quest;
//give options to either show or hide the quest
//show or hide the quest depending on if the user is the owner
