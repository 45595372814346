import React, { Component } from "react";

const carRatings = {
  "Red Bull Racing RB18": 1.0,
  "Mercedes-AMG F1 W13": 0.9,
  "Ferrari SF21": 0.8,
};

class F1Simulator extends Component {
  constructor() {
    super();
    this.state = {
      car: "Red Bull Racing RB18",
      laps: 1,
      speed: 200,
      tire: "Soft",
      weather: "Sunny",
      engineDurability: 100,
      lapTimes: [],
      tireWear: 0,
      engineCondition: 100,
      events: [],
    };

    this.tireOptions = ["Soft", "Medium", "Hard", "Wet"];
    this.carOptions = Object.keys(carRatings);
    this.lapDistance = 5800;
    this.tireDegradation = {
      Soft: 0.02,
      Medium: 0.015,
      Hard: 0.01,
      Wet: 0.03,
    };
    this.engineDegradation = 0.01;
  }

  simulateLap = () => {
    const { speed, tire, weather, engineDurability, laps, car } = this.state;
    const carRating = carRatings[car];
    let lapTimes = [];
    let tireWear = 0;
    let engineCondition = 100;
    let events = [];

    for (let lap = 1; lap <= laps; lap++) {
      let lapTime = this.lapDistance / (speed / 3.6);

      // Tire wear impacts handling, braking, and speed
      const tireEffect = 1 - tireWear / 100;

      // Handling and speed are affected by tire wear
      const handlingEffect = 1 - 0.1 * tireEffect; // Reduces handling
      const speedEffect = 1 - 0.05 * tireEffect; // Reduces speed

      lapTime *=
        (engineCondition / 100) * carRating * handlingEffect * speedEffect;

      // Simulate tire wear and engine degradation
      tireWear += this.tireDegradation[tire];
      engineCondition -= this.engineDegradation;

      // Introduce random events
      if (Math.random() < 0.1) {
        events.push("Missed braking point");
        lapTime += 5; // Penalty for missing braking point
      }

      if (Math.random() < 0.05) {
        events.push("Car spin");
        lapTime += 8; // Penalty for spinning
      }

      if (Math.random() < 0.03) {
        events.push("Car issues");
        lapTime += 15; // Penalty for car issues
      }

      lapTimes.push(lapTime);
    }

    this.setState({ lapTimes, tireWear, engineCondition, events });
  };

  handleCarChange = (e) => {
    this.setState({ car: e.target.value });
  };

  handleLapsChange = (e) => {
    this.setState({ laps: e.target.value });
  };

  handleSpeedChange = (e) => {
    this.setState({ speed: e.target.value });
  };

  handleTireChange = (e) => {
    this.setState({ tire: e.target.value });
  };

  handleWeatherChange = (e) => {
    this.setState({ weather: e.target.value });
  };

  handleEngineDurabilityChange = (e) => {
    this.setState({ engineDurability: e.target.value });
  };

  render() {
    const {
      car,
      laps,
      speed,
      tire,
      weather,
      engineDurability,
      lapTimes,
      tireWear,
      engineCondition,
      events,
    } = this.state;

    return (
      <div>
        <h1>F1 Lap Simulator - Silverstone</h1>
        <label>
          Car:
          <select value={car} onChange={this.handleCarChange}>
            {this.carOptions.map((carOption) => (
              <option key={carOption} value={carOption}>
                {carOption}
              </option>
            ))}
          </select>
        </label>
        <label>
          Number of Laps:
          <input type='number' value={laps} onChange={this.handleLapsChange} />
        </label>
        <label>
          Speed (km/h):
          <input
            type='number'
            value={speed}
            onChange={this.handleSpeedChange}
          />
        </label>
        <label>
          Tire:
          <select value={tire} onChange={this.handleTireChange}>
            {this.tireOptions.map((tireOption) => (
              <option key={tireOption} value={tireOption}>
                {tireOption}
              </option>
            ))}
          </select>
        </label>
        <label>
          Weather:
          <select value={weather} onChange={this.handleWeatherChange}>
            <option value='Sunny'>Sunny</option>
            <option value='Rainy'>Rainy</option>
          </select>
        </label>
        <label>
          Engine Durability (%):
          <input
            type='number'
            value={engineDurability}
            onChange={this.handleEngineDurabilityChange}
          />
        </label>
        <button onClick={this.simulateLap}>Simulate Lap</button>
        {lapTimes.length > 0 && (
          <div>
            <h2>Lap Times:</h2>
            <ul>
              {lapTimes.map((lapTime, index) => (
                <li key={index}>
                  Lap {index + 1}: {lapTime.toFixed(2)} seconds
                </li>
              ))}
            </ul>
          </div>
        )}
        <div>
          <h2>Telemetry:</h2>
          <p>Tire Wear: {tireWear.toFixed(2)}%</p>
          <p>Engine Condition: {engineCondition.toFixed(2)}%</p>
        </div>
        {events.length > 0 && (
          <div>
            <h2>Events:</h2>
            <ul>
              {events.map((event, index) => (
                <li key={index}>
                  {event} on Lap {index + 1}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default F1Simulator;
