import React, { useState, useEffect } from "react";
// import { CSSTransitionGroup } from "react-transition-group";

import { useNavigate } from "react-router-dom";

import { db } from "../../services/firebase";

function ProostPreGame(props) {
  const { userData } = props;

  let history = useNavigate();

  const [newGamePrivate, setNewGamePrivate] = useState(false);
  const [joinGameCode, setJoinGameCode] = useState("");
  const [activeRooms, setActiveRooms] = useState({});

  const [newGamePassword, setNewGamePassword] = useState("");
  const [newGameName, setNewGameName] = useState("");

  const rootRef = "proost/";

  useEffect(() => {
    db.ref(rootRef).on("value", (snapshot) => {
      setActiveRooms(snapshot.val());
      setNewGameName(generateRandomName());
    });
  }, [rootRef]);

  const generateRandomName = () => {
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };

  const changeRoomName = () => {
    setNewGameName(generateRandomName());
  };

  const joinRoom = (code) => {
    db.ref("proost/" + code).once("value", (snapshot) => {
      let selectedRoom = snapshot.val();

      if (selectedRoom) {
        //Does room exist?
        if (selectedRoom.settings.private) {
          //Does room have password?

          let password = prompt("Dette rommet er privat, skriv inn passord:");
          if (password === null || password === "") {
            console.warn("USER ENDED DIALOG");
            return;
          } else {
            if (password === selectedRoom.settings.password) {
              history.replace({
                pathname: "/proost/" + code,
                state: { passwordTyped: true },
              });
            } else {
              console.warn("WRONG PASSWORD");
            }
          }
        } else {
          //room has no password, join
          history.replace({ pathname: "/proost/" + code });
        }
      } else {
        console.warn("NO GAME FOUND");
      }
    });
  };
  const createRoom = () => {
    // A post entry.
    let postData = {
      settings: {
        name: newGameName,
        allow_entries: true,
        allow_new_players: false,
        allow_screen_tap: false,
        password: newGamePassword,
        private: newGamePrivate,
        created_by: userData.ip_address,
      },
    };

    // Get a key htmlFor a new Post.

    // Write the new post's data simultaneously in the posts list and the user's post list.
    let updates = {};
    updates[rootRef + "/" + newGameName] = postData;

    const location = {
      pathname: "/proost/" + newGameName,
      state: { passwordTyped: true },
    };
    history.replace(location);

    return db.ref().update(updates);
  };

  const showRoomInfo = (roomCode) => {
    let data = {};
    db.ref(rootRef + roomCode + "/settings").on("value", (snapshot) => {
      data = snapshot.val();
    });

    return (
      <span>
        <h4>{data.name}</h4>
        <span>{data.created_by}</span>
        {data.private ? (
          <i className='bi bi-lock-fill rooms-status private'></i>
        ) : (
          <i className='bi bi-unlock-fill rooms-status'></i>
        )}
      </span>
    );
  };

  return (
    <div className='App'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <span>
              <h1 style={{ display: "inline-block" }}>Proost</h1>{" "}
              <span
                style={{ verticalAlign: "top" }}
                className='badge badge-primary'
              >
                Beta
              </span>
            </span>
            <p>
              Proost er spillet som du lager selv!<br></br>
              Inspirert av Børst kan du nå lage spørsmålene "on-the-fly" og se
              dem på skjermen.
            </p>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className='row'>
          <div className='col-6'>
            <h3>Bli med i et eksisterende spill</h3>
            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>Rom kode</label>
              <div className='input-group'>
                <input
                  className='form-control'
                  type='text'
                  placeholder='Skriv inn romkoden'
                  value={joinGameCode}
                  onChange={(e) => setJoinGameCode(e.target.value)}
                ></input>
              </div>
              <small id='emailHelp' className='form-text text-muted'>
                Feltet tar ikke høyde for store og små bokstaver
              </small>
            </div>
            <button
              className='btn btn-primary btn-block'
              onClick={(e) => joinRoom(joinGameCode)}
            >
              Bli med i rom
            </button>
          </div>

          <div className='col-6'>
            <h3>Opprett nytt spill</h3>

            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>Rom kode</label>
              <div className='input-group'>
                <input
                  disabled
                  className='form-control'
                  type='text'
                  value={newGameName}
                ></input>
                <div className='input-group-append'>
                  <button
                    onClick={changeRoomName}
                    className='btn btn-primary'
                    type='button'
                  >
                    <i className='bi bi-arrow-clockwise'></i>
                  </button>
                </div>
              </div>
              <small id='emailHelp' className='form-text text-muted'>
                Romkoden kan ikke endres
              </small>
            </div>

            <div className='form-check'>
              <input
                className='form-check-input'
                type='checkbox'
                checked={newGamePrivate}
                onChange={(e) => setNewGamePrivate(!newGamePrivate)}
                id='private'
              />
              <label className='form-check-label' htmlFor='private'>
                Privat spill
              </label>
            </div>

            {newGamePrivate ? (
              <div className='form-group'>
                <label htmlFor='exampleFormControlInput1'>Passord</label>
                <input
                  className='form-control'
                  type='text'
                  placeholder='Skriv inn et passord htmlFor spillet'
                  value={newGamePassword}
                  onChange={(e) => setNewGamePassword(e.target.value)}
                />
              </div>
            ) : null}

            <br></br>
            <button className='btn btn-primary btn-block' onClick={createRoom}>
              Opprett rom
            </button>
          </div>
        </div>
        <br></br>
        <hr className='divider' />
        <div className='row'>
          <div className='col-12'>
            <h3>Aktive spill</h3>
            {activeRooms ? (
              <div className='row'>
                {Object.keys(activeRooms).map((key, i) => (
                  <div key={key} className='game col-4'>
                    {showRoomInfo(key)}
                    <button
                      className='btn btn-primary btn-block'
                      onClick={(e) => joinRoom(key)}
                    >
                      Bli med i rom
                    </button>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProostPreGame;
