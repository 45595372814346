import React from 'react'

import "./spy.css"

const Spy = (props) => {
  // const {globalSettings, userData} = props;



  return (
    <div className="App">
     <div class="text-center">
      <div className="row">
            <div className="col-12">
              <span>
                <h1 style={{ display: "inline-block" }}>Spy</h1>{" "}
                <span
                  className="badge badge-warning"
                >
                  Planlegging
                </span>
              </span>
              <p>Alle får en hemlighet de skal prøve å formidle til rett person, men noen prøver også å få tak i hemligheten</p>
              <p>Vinn spillet med å formidle det hemmelige ordet til din ukjente partner før de andre klarer å finne ut av hva ordet er</p>
              <ul style={{ listStyle: "none" }}>
                <li>Chat for alle spillere</li>
                <li>Privatchat mellom spillere</li>
                <li>Logikk for å lage et random ord som 2 eller flere personer har</li>
              </ul>
          </div>
        </div>
     </div>
    </div>
  )
}

export default Spy;
