import React, { useState, useEffect } from "react";

import "./calculator.css";

const Calculator = (props) => {
  // const {globalSettings, userData} = props;
  const [order, setOrder] = useState([]);
  const [previousOrders, setPreviousOrders] = useState([]);
  const [halfPrice, setHalfPrice] = useState(false);

  const registerItem = (e) => {
    if (order) {
      setOrder([...order, e]);
    } else {
      setOrder(e);
    }
  };

  const resetOrder = () => {
    if (previousOrders) {
      setPreviousOrders([...previousOrders, order]);
    } else {
      setPreviousOrders(order);
    }

    setOrder([]);
    setHalfPrice(false);

    console.log(previousOrders);
  };

  const cutOrder = () => {
    setHalfPrice(true);
  };

  const CurrentOrder = () => {
    let items = [];
    for (const item of order) {
      items.push(
        <div className="col-12 left" key={item.i}>
          {item.name} - {item.price}kr
        </div>
      );
      console.log("item", item);
    }

    return (
      <div className="row">
        <h2>Current order</h2>
        {items}
      </div>
    );
  };

  const Total = () => {
    let totalPrice = 0;
    if (order) {
      for (const item of order) {
        totalPrice = totalPrice + item.price;
      }
    } else {
      totalPrice = 0;
    }

    if (halfPrice) {
      totalPrice = totalPrice / 2;
    }
    return (
      <div className="col-12 orderBar">
        <div className="row">
          <div className="col-md-6  price">Total sum : {totalPrice}kr</div>
          <div className="col-3">
            <button onClick={resetOrder} className="btn btn-lg btn-success">
              RESET
            </button>
          </div>
          <div className="col-3">
            <button onClick={cutOrder} className="btn btn-lg btn-warning">
              50%
            </button>
          </div>
        </div>
      </div>
    );
  };

  const Item = (color) => {
    return (
      <div
        onClick={() => registerItem(color)}
        className={`col-sm-4 col-md-2 item ${color.color}`}
      >
        <div> {color.name} </div>
        <div> {color.price}kr</div>
      </div>
    );
  };

  return (
    <div className="App padding">
      <div class="text-center">
        <Total></Total>
        <div className="row">
          <Item color="green" name="Fatøl" price={98}></Item>
          <Item color="green" name="Carlsberg" price={98}></Item>
          <Item color="green" name="Peroni" price={98}></Item>
          <Item color="green" name="Sol" price={98}></Item>
          <Item color="green" name="1664" price={98}></Item>

          <Item color="yellow" name="Sommersby eple" price={98}></Item>
          <Item color="yellow" name="Shaker Ornage" price={98}></Item>
          <Item color="yellow" name="Shaker Passion" price={98}></Item>
          <Item color="yellow" name="Smirnoff ice" price={98}></Item>
          <Item color="yellow" name="Små bobler" price={98}></Item>

          <Item color="pink" name="Hvitvin glass" price={98}></Item>
          <Item color="pink" name="Hvitvin flaske" price={495}></Item>
          <Item color="pink" name="Rødvin glass" price={98}></Item>
          <Item color="pink" name="Rødvin flaske" price={495}></Item>
          <Item color="pink" name="Prosecco flaske" price={495}></Item>

          <Item color="red" name="Jägermeister" price={98}></Item>
          <Item color="red" name="Sambucca" price={98}></Item>
          <Item color="red" name="Fireball" price={98}></Item>
          <Item color="red" name="Tequila" price={98}></Item>
          <Item color="red" name="Fisk" price={98}></Item>
          <Item color="red" name="Grønn Gajol" price={98}></Item>
          <Item color="red" name="Gul Gajol" price={98}></Item>
          <Item color="red" name="Vodka" price={98}></Item>
          <Item color="red" name="Små sure Eple" price={98}></Item>
          <Item color="red" name="Små sure Cola" price={98}></Item>
          <Item color="red" name="Små sure Jordbær" price={98}></Item>
          <Item color="red" name="Fernet" price={98}></Item>

          <Item color="orange" name="Redbull boks" price={106}></Item>

          <Item color="blue" name="Vodka Redbull" price={148}></Item>
          <Item color="blue" name="Vodka Cranberry" price={148}></Item>
          <Item color="blue" name="Vodka Russchia" price={148}></Item>
          <Item color="blue" name="Gin Russchian" price={148}></Item>
          <Item color="blue" name="Gin Tonic" price={148}></Item>
          <Item color="blue" name="Jack Coke" price={148}></Item>
          <Item color="blue" name="Cuba Libre" price={148}></Item>
          <Item color="blue" name="Mojito" price={148}></Item>
          <Item color="blue" name="Berry Mojito" price={148}></Item>
          <Item color="blue" name="Rum Punch" price={148}></Item>
          <Item color="orange" name="Div Drink" price={148}></Item>
        </div>
        <CurrentOrder></CurrentOrder>
      </div>
    </div>
  );
};

export default Calculator;
