import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyBDug42KfdahdAznjbacTPowLmaTWcV2RU",
  authDomain: "plawodrink.firebaseapp.com",
  databaseURL:
    "https://plawodrink-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "plawodrink",
  storageBucket: "plawodrink.appspot.com",
  messagingSenderId: "590475180872",
  appId: "1:590475180872:web:c66888dac7d6e631e5917d",
};

firebase.initializeApp(config);

export const auth = firebase.auth;

export const db = firebase.database();
