import React, { useState, useEffect } from "react";
import { TransitionGroup } from "react-transition-group";
import _ from "lodash";

import { useNavigate } from "react-router-dom";

import { db } from "../../services/firebase";
import { useParams } from "react-router-dom";

function Proost(props) {
  const { userData } = props;
  const { id } = useParams();

  let history = useNavigate();
  const [authorized, setAuthorized] = useState(false);

  const rootRef = "proost/" + id + "/";

  const [questions, setQuestions] = useState([]);
  const [gameSettings, setgameSettings] = useState([]);
  const [joinGamePassword, setJoinGamePassword] = useState([]);

  const [players, setPlayers] = useState([]);
  const [newPlayerName, setNewPlayerName] = useState([]);
  const [randomColor, setRandomColor] = useState("#004499");
  const [randomQuestion, setRandomQuestion] = useState();
  const [randomQuestionType, setRandomQuestionType] = useState();
  const [text, setText] = useState("");
  const [type, setType] = useState("Generelt");

  useEffect(() => {
    db.ref(rootRef).on("value", (snapshot) => {
      if (snapshot.val()) {
        //if game is private
        if (snapshot.val().settings.private) {
          if (history.location.state && history.location.state.passwordTyped) {
            setAuthorized(true);
          }
        } else {
          setAuthorized(true);
        }
      } else {
        console.warn("NO GAME FOUND");
      }
    });
  }, [history, rootRef]);

  useEffect(() => {
    db.ref(rootRef + "questions").on("value", (snapshot) => {
      if (snapshot.val()) {
        const sampleArray = _.toArray(snapshot.val());
        setQuestions(sampleArray);
      }
    });
  }, [rootRef]);

  useEffect(() => {
    db.ref("proost/" + id + "/settings").on("value", (snapshot) => {
      if (snapshot.val()) {
        setgameSettings(snapshot.val());
      }
    });
  }, [id]);

  useEffect(() => {
    db.ref(rootRef + "questions").on("value", (snapshot) => {
      if (snapshot.val()) {
        const sampleArray = _.toArray(snapshot.val());
        setQuestions(sampleArray);
      }
    });
  }, [rootRef]);

  useEffect(() => {
    db.ref(rootRef + "players").on("value", (snapshot) => {
      if (snapshot.val()) {
        const sampleArray = _.toArray(snapshot.val());
        setPlayers(sampleArray);
      }
    });
  }, [rootRef]);

  const resetState = () => {
    setText("");
  };

  const getRandomNumber = (max) => {
    let min = 0;

    return Math.floor(Math.random() * (max - min) + min);
  };

  const getRandomQuestion = () => {
    if (
      !gameSettings.allow_screen_tap &&
      userData.ip_address !== gameSettings.created_by
    ) {
      return;
    }
    if (questions.length > 0) {
      let number = getRandomNumber(questions.length);
      if (questions) {
        let question = questions[number].text;
        if (questions[number].type) {
          setRandomQuestionType(questions[number].type);
        } else {
          setRandomQuestionType("");
        }

        var containsPlayer = question.includes("@spiller");

        //If the question contain @spiller we have to replace with a random player
        if (containsPlayer) {
          let count = (question.match(/@spiller/g) || []).length;
          if (count > 1) {
            for (let i = 0; i < count; i++) {
              let randomPlayer = getRandomPlayer();
              question = _.replace(question, /@spiller/, randomPlayer.name);
            }
          } else {
            let randomPlayer = getRandomPlayer();
            question = _.replace(question, /@spiller/, randomPlayer.name);
          }
        }

        setRandomQuestion(question);
        getRandomColor();
      }
    } else {
      setRandomQuestion("Det finnes ingen spørsmål, legg til noen da vell 🥳");
    }
  };

  const getRandomColor = () => {
    setRandomColor("#" + (((1 << 24) * Math.random()) | 0).toString(16));
  };

  const getRandomPlayer = () => {
    return players[getRandomNumber(players.length)];
  };

  const questionHTML = () => {
    if (randomQuestion) {
      return (
        <div className='question'>
          <h2>{randomQuestionType}</h2>
          {randomQuestion}
        </div>
      );
    } else {
      return (
        <div className='question'>
          Trykk på skjermen for å få opp et tilfeldig spørsmål
        </div>
      );
    }
  };

  const writeNewQuestion = () => {
    // A post entry.
    let postData = {
      text: text,
      by: userData.ip_address,
      type: type,
    };

    // Get a key for a new Post.
    let newQuestionKey = db.ref().child("proost").push().key;

    // Write the new post's data simultaneously in the posts list and the user's post list.
    let updates = {};
    updates[rootRef + "questions/" + newQuestionKey] = postData;

    resetState();
    return db.ref().update(updates);
  };

  const addPlayer = () => {
    // A post entry.
    let postData = {
      name: newPlayerName,
      added_by: userData.ip_address,
    };

    // Get a key for a new Post.
    let newQuestionKey = db
      .ref()
      .child(rootRef + "players")
      .push().key;

    postData.id = newQuestionKey;
    // Write the new post's data simultaneously in the posts list and the user's post list.
    let updates = {};
    updates[rootRef + "players/" + newQuestionKey] = postData;

    setNewPlayerName("");
    return db.ref().update(updates);
  };

  const removePlayer = (player) => {
    let updates = {};
    updates[rootRef + "players/" + player.id] = {};

    return db.ref().update(updates);
  };

  const joinGameWithPassword = () => {
    if (joinGamePassword === gameSettings.password) {
      history.replace({
        state: { passwordTyped: true },
      });
      setAuthorized(true);
    }
  };

  const changeEntrySetting = (e) => {
    let updates = {};

    if (e.target.name === "allow_entries") {
      updates[rootRef + "settings/allow_entries"] = !gameSettings.allow_entries;
    }

    if (e.target.name === "allow_new_players") {
      updates[rootRef + "settings/allow_new_players"] =
        !gameSettings.allow_new_players;
    }

    if (e.target.name === "allow_screen_tap") {
      updates[rootRef + "settings/allow_screen_tap"] =
        !gameSettings.allow_screen_tap;
    }

    return db.ref().update(updates);
  };

  const getPlaceholderText = () => {
    if (type === "Kastelek") {
      return "Kast til den med mest ...";
    }

    if (type === "Pekelek") {
      return "Hvem er mest sannsynlig til å ...";
    }

    if (type === "Generelt") {
      return "Skriv noe du synes hadde vært gøy";
    }

    if (type === "Utfordring") {
      return "@spiller må gjøre...";
    }

    if (type === "Utfordring") {
      return "@spiller må gjøre...";
    }

    if (type === "Jeg har aldri") {
      return "Jeg har aldri...";
    }

    // return "Skriv inn noe her";
  };

  return (
    <div className='App'>
      {authorized ? (
        <section>
          {userData.ip_address === gameSettings.created_by ? (
            <div className='row container'>
              <span className='col-12'>
                <h4>Instillinger</h4>
              </span>

              <span className='col-4'>
                Brukere kan stille spørsmål
                <label className='switch'>
                  <input
                    name='allow_entries'
                    checked={gameSettings.allow_entries}
                    onChange={changeEntrySetting}
                    type='checkbox'
                  ></input>
                  <span className='slider round'></span>
                </label>
              </span>
              <span className='col-4'>
                Brukere kan legge til spillere
                <label className='switch'>
                  <input
                    name='allow_new_players'
                    checked={gameSettings.allow_new_players}
                    onChange={changeEntrySetting}
                    type='checkbox'
                  ></input>
                  <span className='slider round'></span>
                </label>
              </span>
              <span className='col-4'>
                Brukere kan bytte spørsmål
                <label className='switch'>
                  <input
                    name='allow_screen_tap'
                    checked={gameSettings.allow_screen_tap}
                    onChange={changeEntrySetting}
                    type='checkbox'
                  ></input>
                  <span className='slider round'></span>
                </label>
              </span>
            </div>
          ) : null}
          <div className='row container'>
            <span className='col-12'>
              <h4>
                Romkode: {gameSettings.name}
                {gameSettings.password ? (
                  <span> / Passord: {gameSettings.password}</span>
                ) : null}
              </h4>
            </span>
          </div>

          <div
            className='questionContainer'
            style={{ backgroundColor: randomColor }}
            onClick={getRandomQuestion}
          >
            <TransitionGroup
              className='container text-center'
              transitionName='example'
              transitionEnterTimeout={300}
              transitionLeaveTimeout={300}
            >
              {questionHTML()}
            </TransitionGroup>
          </div>

          <br></br>
          {gameSettings.allow_entries ||
          userData.ip_address === gameSettings.created_by ? (
            <TransitionGroup
              transitionName='example'
              transitionEnterTimeout={300}
              transitionLeaveTimeout={300}
            >
              <div className='questionFooter container'>
                <div className='row'>
                  <div className='col-8'>
                    <div className='form-group'>
                      <label htmlFor='exampleFormControlInput1'>Spørsmål</label>
                      <div className='input-group'>
                        <input
                          className='form-control'
                          type='text'
                          placeholder={getPlaceholderText()}
                          value={text}
                          onChange={(e) => setText(e.target.value)}
                        />
                      </div>
                      <small id='emailHelp' className='form-text text-muted'>
                        Skriv <span className='text-highlight'>@spiller</span>{" "}
                        for at spillet skal velge en tilfeldig person fra
                        spillerlisten
                      </small>
                    </div>

                    <div className='form-group'></div>
                  </div>
                  <div className='col-4'>
                    <div className='form-group'>
                      <label htmlFor='exampleFormControlSelect1'>
                        Velg type
                      </label>
                      <select
                        className='form-control'
                        id='exampleFormControlSelect1'
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option>Generelt</option>
                        <option>Utfordring</option>
                        <option>Kastelek</option>
                        <option>Pekelek</option>
                        <option>Jeg har aldri</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <button
                      className='btn btn-primary btn-block'
                      onClick={writeNewQuestion}
                    >
                      Send inn
                    </button>
                  </div>
                </div>
              </div>
            </TransitionGroup>
          ) : (
            <div className='status-text'>
              <p>Spørsmål har blitt slått av</p>
            </div>
          )}
          <br></br>
          {gameSettings.allow_new_players ||
          userData.ip_address === gameSettings.created_by ? (
            <div className='container'>
              <div className='row'>
                <div className='col-6'>
                  <h5>Legg til ny spiller</h5>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='input-group'>
                        <input
                          className='form-control'
                          type='text'
                          placeholder='Spillernavn'
                          value={newPlayerName}
                          onChange={(e) => setNewPlayerName(e.target.value)}
                        ></input>
                        <div className='input-group-append'>
                          <button
                            onClick={addPlayer}
                            className='btn btn-primary'
                            type='button'
                          >
                            Legg til spiller
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6 players'>
                  <h5>Spillere</h5>
                  <TransitionGroup
                    transitionName='example'
                    transitionEnterTimeout={300}
                    transitionLeaveTimeout={300}
                  >
                    {players.map((player, i) => (
                      <span key={i} className='badge badge-primary player'>
                        {player.name}
                        <span
                          onClick={(e) => removePlayer(player)}
                          className='delete'
                          aria-hidden='true'
                        >
                          ×
                        </span>
                      </span>
                    ))}
                  </TransitionGroup>
                </div>
              </div>
            </div>
          ) : (
            <div className='status-text'>
              <p>Legge til spiller har blitt slått av</p>
            </div>
          )}
        </section>
      ) : (
        <div className='container'>
          <h3>Dette rommet er privat - Skriv passordet for å bli med</h3>
          <div className='form-group'>
            <label htmlFor='exampleFormControlInput1'>Passord</label>
            <input
              className='form-control'
              type='text'
              placeholder='Skriv inn et passord htmlFor spillet'
              value={joinGamePassword}
              onChange={(e) => setJoinGamePassword(e.target.value)}
            />
          </div>
          <button
            className='btn btn-primary btn-block'
            onClick={joinGameWithPassword}
          >
            Bli med
          </button>
        </div>
      )}
    </div>
  );
}

export default Proost;
