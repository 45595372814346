import React from "react";

import "../assets/css/bw.css";
// import "bootstrap/dist/css/bootstrap.min.css";

function BTW() {
  return (
    <div className="bw-background">
      <div className="bw-container">
        <div class="box white-bg shadow-generic">
          <a href="https://www.google.com">
            <img
              alt="img"
              class="img-small"
              src="https://ai.devoteam.com/wp-content/uploads/sites/91/2018/05/google-logo-icon-png-transparent-background.png"
            />
          </a>
        </div>

        <div class="box dark-bg shadow-generic">
          <a href="https://play.spotify.com/">
            <img
              alt="img"
              class="img-small"
              src="https://www.freepnglogos.com/uploads/spotify-logo-png/file-spotify-logo-png-4.png"
            />
          </a>
        </div>

        <div class="box white-bg shadow-generic">
          <a href="https://photos.google.com/">
            <img
              alt="img"
              class="img-small"
              src="https://assets.stickpng.com/images/5847faedcef1014c0b5e48cc.png"
            />
          </a>
        </div>

        <div class="box white-bg shadow-generic">
          <a href="https://www.dplay.no/">
            <img
              alt="img"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Dplay_logo_2019.svg/1280px-Dplay_logo_2019.svg.png"
            />
          </a>
        </div>

        <div class="box dark-bg shadow-generic">
          <a href="https://www.netflix.com">
            <img
              alt="img"
              src="https://assets.stickpng.com/images/580b57fcd9996e24bc43c529.png"
            />
          </a>
        </div>

        <div class="box white-bg shadow-generic">
          <a href="https://tv.nrk.no/">
            <img
              alt="img"
              src="https://kommunikasjon.ntb.no/data/images/00111/10798023-0904-4d58-b068-a3dc24c7905d.png"
            />
          </a>
        </div>

        <div class="box dark-bg shadow-generic">
          <a href="https://www.twe.no">
            <img
              alt="img"
              class="img-small"
              src="https://www.telenor.no/binaries/privat/kundeservice/tvhjelp/bruk-twe/tv-meny/portal/T-We-Logo-Pos-RGB_tcm94-373161.png"
            />
          </a>
        </div>

        <div class="box white-bg shadow-generic">
          <a href="https://www.hbo.com">
            <img
              alt="img"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/HBO_logo.svg/1024px-HBO_logo.svg.png"
            />
          </a>
        </div>

        <div class="box white-bg shadow-generic">
          <a href="https://www.vg.no">
            <img
              alt="img"
              class="img-small"
              src="https://pbs.twimg.com/profile_images/840402585735561216/UcRGvMz8.png"
            />
          </a>
        </div>

        <div class="box white-bg shadow-generic">
          <a href="https://www.db.no">
            <img
              alt="img"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Dagbladet_logo.svg/1200px-Dagbladet_logo.svg.png"
            />
          </a>
        </div>

        <div class="box white-bg shadow-generic">
          <a href="https://www.facebook.com">
            <img
              alt="img"
              class="img-small"
              src="https://assets.stickpng.com/thumbs/584ac2d03ac3a570f94a666d.png"
            />
          </a>
        </div>

        <div class="box white-bg shadow-generic">
          <a href="https://www.messenger.com">
            <img
              alt="img"
              class="img-small"
              src="https://facebookbrand.com/wp-content/uploads/2020/10/Logo_Messenger_NewBlurple-399x399-1.png?w=399&h=399"
            />
          </a>
        </div>

        <div class="box dark-bg shadow-generic">
          <a href="https://www.viaplay.no/">
            <img
              alt="img"
              src="https://assets.viaplay.tv/frontend-2020121703/images/header-logo-large.png"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default BTW;
