import React from 'react'

import "./riddler.css"

const Riddler = (props) => {
  // const {globalSettings, userData} = props;



  return (
    <div className="App">
     <div class="text-center">This is a new component, Riddler</div>
    </div>
  )
}

export default Riddler;
