import React from "react";

import Suggestions from "./Suggestions"


function Home() {

  return (
    <div className="App">
        <h1 className="text-center">PlawoXYZ</h1>
        <Suggestions></Suggestions>
    </div>
  );
}

export default Home;
