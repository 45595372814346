import React, { useState, useEffect } from "react";
import _ from "lodash";

import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import moment from "moment"

import { db } from "../../services/firebase";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faCheck,
  //   faExclamation,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

import "./fridge.css";

const gridClass = "col-lg-6 col-sm-12"

function Fridge(props) {
  const { userAuth } = props;
  const { id } = useParams();

  let history = useNavigate();

  const rootRef = "fridge/";

  const [fridgeStock, setFridgeStock] = useState([]);
  const [fridgeCategories, setFridgeCategories] = useState([]);
  const [showAddNewItem, setShowAddNewItem] = useState(false);

  const [name, setName] = useState("");
  const [category, setCategory] = useState("1");
  const [shelf, setShelf] = useState("1");
  const [quantity, setQuantity] = useState("");
  const [barcode, setBarcode] = useState("");
  const [expDate, setExpDate] = useState("");


  useEffect(() => {
    db.ref(rootRef + "stock").on("value", (snapshot) => {
      if (snapshot.val()) {
        const sampleArray = _.toArray(snapshot.val());
        setFridgeStock(sampleArray);
      }
    });
  }, [rootRef]);

  useEffect(() => {
    db.ref(rootRef + "/categories").on("value", (snapshot) => {
      if (snapshot.val()) {
        setFridgeCategories(snapshot.val());
      }
    });
  }, [id]);

  const resetState = () => {
    setName("");
    setCategory("1");
    setShelf("1");
    setBarcode("");
    setExpDate("");
    setQuantity("");
  };

  const addNewItem = () => {
    // Get a key for a new Post.
    let newQuestionKey = db
      .ref()
      .child(rootRef + "stock")
      .push().key;


    // A post entry.
    let postData = {
      avaliable: true,
      barcode: null,
      category: category ? category : "1",
      date_added: Date(),
      expiration_date: expDate ? expDate : Date(),
      id: newQuestionKey,
      name: name,
      quantity: quantity,
      shelf: shelf
    };

    // Write the new post's data simultaneously in the posts list and the user's post list.
    let updates = {};
    updates[rootRef + "stock/" + newQuestionKey] = postData;

    resetState();
    return db.ref().update(updates);
  };

  const changeEntrySetting = (e, item) => {
    let updates = {};

    if (e.target.name === "item_avaliable") {
      if (item.quantity === 0 && !item.avaliable) {
        updates[rootRef + "stock/" + item.id + "/avaliable"] = true;
        updates[rootRef + "stock/" + item.id + "/quantity"] = 1;
      } else {
      updates[rootRef + "stock/" + item.id + "/avaliable"] = !item.avaliable;
        updates[rootRef + "stock/" + item.id + "/quantity"] = 0;
      }
    }

    if (e.target.name === "add_quantity") {
      if (item.quantity === 0) {
        updates[rootRef + "stock/" + item.id + "/avaliable"] = true;
      }
      updates[rootRef + "stock/" + item.id + "/quantity"] = item.quantity + 1;


    }
    if (e.target.name === "remove_quantity") {
      if (item.quantity === 1) {
        updates[rootRef + "stock/" + item.id + "/avaliable"] = false;
      }
      updates[rootRef + "stock/" + item.id + "/quantity"] = item.quantity - 1;

    }

    return db.ref().update(updates);
  };


  const showItem = (item, i) => {

    var questClasses = classNames({
      "table-success": item.avaliable,
      "table-danger": !item.avaliable,
    });

    return (
      <tr key={i} className={questClasses}>
        <td>{item.name}</td>
        <td>
          <div class="input-group">
            <div class="input-group-append">
              <button className="btn btn-outline-danger .d-sm-none .d-md-block" name='remove_quantity' onClick={(e) => changeEntrySetting(e, item)} type="button">-</button>
              <span className="button-text-middle">{item.quantity}</span>
              <button className="btn btn-outline-success" name='add_quantity' onClick={(e) => changeEntrySetting(e, item)} type="button" >+</button>
            </div>
          </div>
        </td>
        <td>{
          moment(item.expiration_date).format('MMMM Do YYYY')
        }</td>
        <td>
          <label className='switch'>
            <input
              name='item_avaliable'
              checked={item.avaliable}
              onChange={(e) => changeEntrySetting(e, item)}
              type='checkbox'
            ></input>
            <span className='slider round'></span>
          </label>
        </td>
      </tr>

    );
  };

  var addNewItemClasses = classNames({
    "hidden": !showAddNewItem,
    "container": true
  });

  return (
    <div className='App fridge'>
      <section name="add new item section">
        <div className='container'>
          <span className='col-12'>
            <h4>Fridge</h4>
          </span>
          <div className={addNewItemClasses}>
            <div className='row'>
              <div className='col-12'>
                <div className='form-group'>
                  <label htmlFor='exampleFormControlInput1'>
                    Varenavn
                  </label>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      type='text'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className={gridClass}>
                <div className='form-group'>
                  <label htmlFor='exampleFormControlSelect1'>
                    Kategori
                  </label>
                  <select
                    className='form-control'
                    id='exampleFormControlSelect1'
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="1">Fryser</option>
                    <option value="2">Kjøpeskap</option>
                    <option value="3">Tørrskap</option>
                    <option value="4">Annet</option>
                  </select>
                </div>
              </div>
              {category === "1" ? <div className={gridClass}>
                <div className='form-group'>
                  <label htmlFor='exampleFormControlSelect1'>
                    Hylle
                  </label>
                  <select
                    className='form-control'
                    id='exampleFormControlSelect1'
                    value={shelf}
                    onChange={(e) => setShelf(e.target.value)}
                  >
                    <option value="1">Bakevarer</option>
                    <option value="2">Kjøtt/kjappe retter</option>
                    <option value="3">Grønnsaker</option>
                  </select>
                </div>

              </div> : null}
            </div>
            <div className='row'>
              <div className={gridClass}>
                <div className='form-group'>
                  <label htmlFor='exampleFormControlInput1'>
                    Utløpsdato / Spises før
                  </label>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      type='date'
                      value={expDate}
                      onChange={(e) => setExpDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className={gridClass}>
                <div className='form-group'>
                  <label htmlFor='exampleFormControlInput1'>
                    Antall
                  </label>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      type='number'
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <button
                  className='btn btn-success btn-block'
                  onClick={addNewItem}
                  disabled={!expDate || !shelf || !category || !name || !quantity}
                >
                  Legg til
                </button>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className='row'>
            <div className='col-12'>
              <button
                className='btn btn-dark btn-block'
                onClick={(e) => setShowAddNewItem(!showAddNewItem)}
              >
                {!showAddNewItem ? <span>Åpne</span> : <span>Lukk</span>}

              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='container' name="show items section">
        <div className="row">
          <h3>Fryser - Bakevarer</h3>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Vare</th>
                <th scope="col">Antall</th>
                <th scope="col">Utløpsdato</th>
                <th scope="col">Tilgjengelig</th>
              </tr>
            </thead>
            <tbody>
              {fridgeStock.filter(function (item) {
                return item.category === "1" && item.shelf === "1";
              }).map((item, i) => showItem(item, i))}
            </tbody>
          </table>

          <h3>Fryser - Kjøtt, ferdigretter</h3>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Vare</th>
                <th scope="col">Antall</th>
                <th scope="col">Utløpsdato</th>
                <th scope="col">Tilgjengelig</th>
              </tr>
            </thead>
            <tbody>
              {fridgeStock.filter(function (item) {
                return item.category === "1" && item.shelf === "2";
              }).map((item, i) => showItem(item, i))}

            </tbody>
          </table>

          <h3>Fryser - Grønnsaker</h3>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Vare</th>
                <th scope="col">Antall</th>
                <th scope="col">Utløpsdato</th>
                <th scope="col">Tilgjengelig</th>
              </tr>
            </thead>
            <tbody>
              {fridgeStock.filter(function (item) {
                return item.category === "1" && item.shelf === "3";
              }).map((item, i) => showItem(item, i))}

            </tbody>
          </table>

          <h3>Kjøleskap</h3>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Vare</th>
                <th scope="col">Antall</th>
                <th scope="col">Utløpsdato</th>
                <th scope="col">Tilgjengelig</th>
              </tr>
            </thead>
            <tbody>
              {fridgeStock.filter(function (item) {
                return item.category === "2";
              }).map((item, i) => showItem(item, i))}

            </tbody>
          </table>

          <h3>Tørrvarer</h3>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Vare</th>
                <th scope="col">Antall</th>
                <th scope="col">Utløpsdato</th>
                <th scope="col">Tilgjengelig</th>
              </tr>
            </thead>
            <tbody>
              {fridgeStock.filter(function (item) {
                return item.category === "3";
              }).map((item, i) => showItem(item, i))}

            </tbody>
          </table>

          <h3>Annet</h3>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Vare</th>
                <th scope="col">Antall</th>
                <th scope="col">Utløpsdato</th>
                <th scope="col">Tilgjengelig</th>
              </tr>
            </thead>
            <tbody>
              {fridgeStock.filter(function (item) {
                return item.category === "4";
              }).map((item, i) => showItem(item, i))}

            </tbody>
          </table>


        </div>
      </section>
    </div>
  );
}

export default Fridge;
