import React, { useState, useEffect } from "react";
import _ from "lodash";

import { useNavigate } from "react-router-dom";

import { db } from "../../services/firebase";
import { useParams } from "react-router-dom";

function SecretSanta(props) {
  const participants = ["Alice", "Bob", "Charlie", "David", "Eve", "Frank"]; // Replace with your list of participants

  const { userData, userAuth } = props;

  let history = useNavigate();

  const rootRef = "secretSanta/";

  const [name, setName] = useState("");
  const [hasSignedUp, setHasSignedUp] = useState(false);
  const [showSignUpDetails, setShowSignUpDetails] = useState(false);
  const [signUpCode, setSignUpCode] = useState("");

  const [assignedPerson, setAssignedPerson] = useState("");
  const [assignPersonError, setAssignedPersonError] = useState("");

  const [personCode, setPersonCode] = useState("");
  const [people, setPeople] = useState([]);

  //Fetch all persons that have signed up
  useEffect(() => {
    db.ref(rootRef + "people").on("value", (snapshot) => {
      if (snapshot.val()) {
        const sampleArray = _.toArray(snapshot.val());
        setPeople(sampleArray);
      }
    });
  }, [rootRef]);

  //check if user has signed up yet
  useEffect(() => {
    for (let i = 0; i < people.length; i++) {
      let object = people[i];
      //   console.log("arra", object);
      if (object.added_by === userAuth.uid) {
        setHasSignedUp(true);

        console.log("PERSON HAS ALLREADY SIGNED UP");
      } else {
        setHasSignedUp(false);
      }
    }
  }, [people, userAuth.uid]);

  const searchPersonByCode = () => {
    for (let i = 0; i < people.length; i++) {
      let object = people[i];
      //   console.log("arra", object);
      if (object.code === personCode) {
        setAssignedPerson(object);
        console.log("FOUND THE PERSON");
        return;
      } else {
        setAssignedPerson("");
      }
    }
  };

  const checkAssignedUserIsValid = () => {
    if (assignedPerson.has_been_assigned) {
      return (
        <h2>
          Du ({assignedPerson.name}) har blitt tildelt{" "}
          {assignedPerson.assigned_person}
        </h2>
      );
    } else {
      return <h2>Du har ikke blitt tildelt person enda.</h2>;
    }
  };

  const writeNewName = () => {
    // A post entry.
    let postData = {
      name: name,
      added_by: userAuth.uid,
      code: generateRandomName(),
      assigned_person: "",
      has_been_assigned: false,
    };

    // Get a key for a new Post.
    let newQuestionKey = db.ref().child("secretSanta").push().key;

    // Write the new post's data simultaneously in the posts list and the user's post list.
    let updates = {};
    updates[rootRef + "people/" + newQuestionKey] = postData;

    setShowSignUpDetails(true);
    resetState();
    setSignUpCode(postData.code);
    return db.ref().update(updates);
  };

  const generateRandomName = () => {
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };

  const resetState = () => {
    setName("");
  };

  // Shuffle the participants array to randomize the assignments
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const [assignments, setAssignments] = useState({});
  const [isAssigned, setIsAssigned] = useState(false);

  const assignSecretSanta = () => {
    const assigned = {};

    // Make a copy of the shuffled participants array
    const shuffledParticipants = [...participants];
    shuffleArray(shuffledParticipants);

    for (let i = 0; i < shuffledParticipants.length; i++) {
      const currentParticipant = shuffledParticipants[i];
      const nextIndex = (i + 1) % shuffledParticipants.length;
      const secretSanta = shuffledParticipants[nextIndex];

      assigned[currentParticipant] = secretSanta;
    }

    setAssignments(assigned);
    setIsAssigned(true);
  };

  return (
    <div className='App'>
      <section>
        <div className='row container'>
          <span className='col-12'>
            <h1>Secret Santa Assignment</h1>
            {/* <h4>Instillinger</h4> */}
          </span>
          {/* <button onClick={assignSecretSanta}>Assign Secret Santas</button> */}
          {!hasSignedUp ? (
            <section>
              <div className='form-group'>
                <label htmlFor='exampleFormControlInput1'>Ditt navn</label>
                <div className='input-group'>
                  <input
                    className='form-control'
                    type='text'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                {/* <small id='emailHelp' className='form-text text-muted'>
              Skriv <span className='text-highlight'>@spiller</span> for at
              spillet skal velge en tilfeldig person fra spillerlisten
            </small> */}
              </div>
              <div className='row'>
                <div className='col-12'>
                  <button
                    className='btn btn-primary btn-block'
                    onClick={writeNewName}
                  >
                    Send inn
                  </button>
                </div>
              </div>
            </section>
          ) : (
            <section>
              <div className='form-group'>
                <label htmlFor='exampleFormControlInput1'>Skriv din kode</label>
                <div className='input-group'>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Skriv inn din kode'
                    value={personCode}
                    onChange={(e) => setPersonCode(e.target.value)}
                  />
                </div>
                <small id='helper' className='form-text white'>
                  Skriv inn koden du ble tildelt når du meldte deg opp for å se
                  om du har fått en person
                </small>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <button
                    className='btn btn-primary btn-block'
                    onClick={searchPersonByCode}
                  >
                    Søk etter din person
                  </button>
                </div>
              </div>

              <div className='row'>
                <div className='col-12 alert'>{assignPersonError}</div>
              </div>
            </section>
          )}

          {showSignUpDetails ? <div>Din kode er: {signUpCode} </div> : null}

          {assignedPerson && hasSignedUp ? (
            <div className='row'>
              <div className='col-12'>{checkAssignedUserIsValid()}</div>
            </div>
          ) : null}
        </div>
      </section>
    </div>
  );
}

export default SecretSanta;
