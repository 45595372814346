import React, { useState, useEffect } from "react";
import { TransitionGroup } from "react-transition-group";

import "bootstrap/dist/css/bootstrap.min.css";

import { db } from "../services/firebase";

function Questions() {
  const [questions, setQuestions] = useState({});

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    db.ref("questions").on("value", (snapshot) => {
      setQuestions(snapshot.val());
    });
  }, []);

  const resetState = () => {
    setFrom("");
    setTo("");
    setText("");
  };

  const writeNewQuestion = () => {
    // A post entry.
    let postData = {
      from: from,
      text: text,
      to: to,
      answered: false,
    };

    // Get a key for a new Post.
    let newQuestionKey = db.ref().child("questions").push().key;

    // Write the new post's data simultaneously in the posts list and the user's post list.
    let updates = {};
    updates["/questions/" + newQuestionKey] = postData;

    resetState();
    return db.ref().update(updates);
  };

  const questionAnswered = (to, text, from, id) => {
    console.log("ID", id);

    let postData = {
      from: from,
      text: text,
      to: to,
      answered: true,
    };

    let updates = {};
    updates["/questions/" + id] = postData;

    return db.ref().update(updates);
  };

  console.log("questions state", questions);

  const questionsArray = Object.keys(questions).map((id, i) =>
    !questions[id].answered ? (
      <div className='col-4'>
        <div className='card col-12' key={i} hidden={questions[id].answered}>
          <div class='card-body'>
            <div> Til :{questions[id].to}</div>
            <div>Spørsmål : {questions[id].text}</div>
            <div> Fra : {questions[id].from}</div>
            <button
              className='btn btn-info'
              onClick={() =>
                questionAnswered(
                  questions[id].to,
                  questions[id].text,
                  questions[id].from,
                  id
                )
              }
            >
              Besvart
            </button>
          </div>
        </div>
        <br></br>
      </div>
    ) : (
      <div></div>
    )
  );

  return (
    <div className='App'>
      <div className='container '>
        <TransitionGroup
          className='row'
          transitionName='example'
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {questionsArray}
        </TransitionGroup>
        <br></br>
        <div className='questionFooter'>
          <div className='row'>
            <input
              className='form-control'
              type='text'
              placeholder='Til'
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />

            <input
              className='form-control'
              type='text'
              placeholder='Spørsmål'
              value={text}
              onChange={(e) => setText(e.target.value)}
            />

            <input
              className='form-control'
              type='text'
              placeholder='Fra'
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />

            <button
              className='btn btn-primary col-12'
              onClick={writeNewQuestion}
            >
              Send inn
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Questions;
