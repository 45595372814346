import React, { useState, useEffect } from "react";
import _ from "lodash";

import { db } from "../../services/firebase";

import "./swg.css";

const Swg = (props) => {
  // const {globalSettings, userData} = props;

  const [players, setPlayers] = useState([]);
  const [gameActive, setGameActive] = useState([]);
  const [newPLayerName, setNewPlayerName] = useState([]);
  const [adminCode, setAdminCode] = useState("");
  const [authenticated, setAuthenticated] = useState(false);

  const rootRef = "swg/";

  const changeEntrySetting = (action, player) => {

    if (action === "remove_life") {
      let life = player.lives - 1;

      if (life === 0) {
        db.ref(rootRef + "players/" + player.id).update({
          lives: life,
          dead: true,
        });
      } else {
        db.ref(rootRef + "players/" + player.id).update({
          lives: life,
        });
      }
    }

    if (action === "add_life") {
      let life = player.lives + 1;

      if (player.dead === true) {
        db.ref(rootRef + "players/" + player.id).update({
          lives: life,
          dead: false,
        });
      } else {
        db.ref(rootRef + "players/" + player.id).update({
          lives: life,
        });
      }
    }

    if (action === "dead") {
      let life = 0;
      db.ref(rootRef + "players/" + player.id).update({
        lives: life,
        dead: true,
      });
    }

    if (action === "staff") {
      db.ref(rootRef + "players/" + player.id).update({
        staff: !player.staff,
      });
    }

    if (action === "start_game") {
      db.ref(rootRef).update({
        game_active: true,
      });
    }

    if (action === "stop_game") {
      db.ref(rootRef).update({
        game_active: false,
      });
    }

  };


  const addPlayer = () => {
    let keys = Object.keys(players)

    //get last id from players
    let last = parseInt(keys[keys.length - 1]);
    let newKey = last + 1;
    let newKeyStringWith0 = "0" + String(newKey);

    // A post entry.
    let postData = {
      name: newPLayerName,
      dead: false,
      img: "generic.jpg",
      lives: 4,
      id: newKeyStringWith0
    };
    // Write the new post's data simultaneously in the posts list and the user's post list.
    let updates = {};
    updates[rootRef + "players/" + newKeyStringWith0] = postData;

    return db.ref().update(updates);
  };


  useEffect(() => {
    db.ref(rootRef).on("value", (snapshot) => {
      if (snapshot.val()) {
        setPlayers(snapshot.val().players);
        setGameActive(snapshot.val().game_active)
      }
    });
  }, [rootRef]);



  const checkAdminCode = () => {
    if (adminCode === "6969") {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  };

  return (
    <div className='App'>
      <div className='text-center'>
        <div className='container'>
          {authenticated ? (
            <div>
              <div className="col-12">
                {gameActive ? <div>Game is active
                  <button
                    name='start_game'
                    className='btn btn-warning btn-block'
                    onClick={(e) =>
                      changeEntrySetting("stop_game")
                    }
                  >
                    STOP GAME
                  </button>

                </div> : <div>
                  Game is not active
                  <button
                    name='start_game'
                    className='btn btn-success btn-block'
                    onClick={(e) =>
                      changeEntrySetting("start_game")
                    }
                  >
                    START GAME
                  </button>
                </div>}
                <br></br>
              </div>
            <div className='col-12'>
              <div>
                  <div className="row">
                    <div className='col-12'>
                      <img
                        className="logo"
                        src={require("../../img/swg/logo.png")}
                        alt="logo"
                      ></img>
                    </div>
                  </div>
                <h1 style={{ display: "inline-block" }}>
                    ADMIN
                </h1>
                <div className='row overflow-hidden'>
                  {Object.keys(players).map((key, i) => (
                    <div
                      key={key}
                      className={
                        players[key].dead
                          ? "col-sm-12 col-md-4 player-border dead"
                          : "col-sm-12 col-md-4 player-border alive"
                      }
                    >
                      <div className="asset__item">
                        <div className="asset__img">
                          <img
                            src={require("../../img/swg/" + players[key].img)}
                            alt={players[key].name}
                          ></img>
                        </div>
                      </div>
                      <h2>
                        {players[key].name} - {players[key].id}
                      </h2>

                      {players[key].staff ?
                        <div>
                          <h3>STAFF</h3>
                        </div> :
                        <div>
                          <h3> Liv igjen: {players[key].lives} </h3>
                        </div>
                      }


                      <div className='container'>
                        <div className="row">
                          <div className='col-6'>
                            <button
                              name='remove_life'
                              className='btn btn-warning btn-block'
                              onClick={(e) =>
                                changeEntrySetting("remove_life", players[key])
                              }
                            >
                              Fjern
                            </button>
                            <br></br>
                          </div>

                          <div className='col-6'>
                            <button
                              name='add_life'
                              className='btn btn-success btn-block'
                              onClick={(e) =>
                                changeEntrySetting("add_life", players[key])
                              }
                            >
                              Add
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className='col-6'>
                            <button
                              name='dead'
                              className='btn btn-danger btn-block'
                              onClick={(e) =>
                                changeEntrySetting("dead", players[key])
                              }
                            >
                              Dead
                            </button>
                          </div>
                          <div className='col-6'>
                            <button
                              name='staff'
                              className='btn btn-info btn-block'
                              onClick={(e) =>
                                changeEntrySetting("staff", players[key])
                              }
                            >
                              Staff
                            </button>
                          </div>
                        </div>


                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
              <br></br>
              <br></br>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2>Legg til spiller</h2>
                  </div>
                </div>
                <div className="row">
                  <div className='col-6'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1'>Navn</label>
                      <div className='input-group'>
                        <input
                          className='form-control'
                          type='text'
                          placeholder='Skriv inn navn på spiller'
                          value={newPLayerName}
                          onChange={(e) => setNewPlayerName(e.target.value)}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <button
                      className='btn btn-primary center'
                      onClick={(e) => addPlayer()}
                    >
                      Add player
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='row'>
              <h1>Not authenticated</h1>
              <div className='col-8'>
                <div className='form-group'>
                  <label htmlFor='exampleInputEmail1'>Admin kode</label>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      type='text'
                        placeholder='Skriv inn spillernavn'
                      value={adminCode}
                      onChange={(e) => setAdminCode(e.target.value)}
                    ></input>
                  </div>
                  <small id='emailHelp' className='form-text text-muted'>
                    Feltet tar ikke høyde for store og små bokstaver
                  </small>
                </div>
              </div>
              <div className='col-4'>
                <button
                  className='btn btn-primary btn-block center'
                  onClick={(e) => checkAdminCode()}
                >
                  Authenticate
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Swg;
