import React, { useState, useEffect } from "react";
import _ from "lodash";

import { useNavigate } from "react-router-dom";

import { db } from "../../services/firebase";
import { useParams } from "react-router-dom";

import "./swg.css";

const Swg = (props) => {
  // const {globalSettings, userData} = props;
  const [players, setPlayers] = useState([]);
  const [gameActive, setGameActive] = useState([]);

  const rootRef = "swg/";

  useEffect(() => {
    db.ref(rootRef).on("value", (snapshot) => {
      if (snapshot.val()) {
        setPlayers(snapshot.val().players);
        setGameActive(snapshot.val().game_active)
      }
    });
  }, [rootRef]);


  return (
    <div className='App'>
      <div className='text-center'>
        <div className='container'>
          {gameActive ? <div>
            <div className="row">
              <div className='col-12'>
                <img
                  className="logo"
                  src={require("../../img/swg/logo.png")}
                  alt="logo"
                ></img>
              </div>
            </div>
            <div className='row overflow-hidden'>
              {Object.keys(players).map((key, i) => (

                <div
                  key={key}
                  className={players[key].dead ? "col-2 dead" : "col-2 alive"}
                >
                  <div className="asset__item">
                    <div className="asset__img">
                      <img
                        src={require("../../img/swg/" + players[key].img)}
                        alt={players[key].name}
                      ></img>
                      {players[key].id} - {players[key].name}
                    </div>
                  </div>

                  <h3 className={players[key].dead ? "red" : "green"}>
                    {players[key].dead ? (
                      <div>DEAD</div>
                    ) : (
                      <div>Liv igjen: {players[key].lives}</div>
                    )}
                  </h3>
                  <h4>

                  </h4>
                </div>

              ))}
            </div>
          </div> :
            <div className="container-big">
              <img
                className="big"
                src={require("../../img/swg/logo.png")}
                alt="logo"
              ></img>

            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Swg;
