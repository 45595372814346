import React, { useState, useEffect } from "react";
import { TransitionGroup } from "react-transition-group";
import _ from "lodash";

import { useNavigate } from "react-router-dom";

import { db } from "../../services/firebase";
import { useParams } from "react-router-dom";

import "./cah.css";

function CardsAgainstHumanity(props) {
  const { userData, userAuth } = props;
  const { id } = useParams();

  let history = useNavigate();
  const [authorized, setAuthorized] = useState(false);

  const rootRef = "cah/games/" + id + "/";

  const [questions, setQuestions] = useState([]);
  const [gameSettings, setgameSettings] = useState([]);
  const [joinGamePassword, setJoinGamePassword] = useState([]);

  const [players, setPlayers] = useState([]);
  const [thisPlayer, setThisPlayer] = useState([]);
  const [playerCards, setPlayerCards] = useState([]);
  // const [newPlayerName, setNewPlayerName] = useState([]);
  const [randomColor, setRandomColor] = useState("#004499");
  const [randomQuestion, setRandomQuestion] = useState();
  const [randomQuestionType, setRandomQuestionType] = useState();

  const [selectedCard, setSelectedCard] = useState([null, ""]);

  //TODO: Give cards to all players on game start
  //TODO: Functionality to have timer if wanted (timer counts down each round)
  //TODO: Functionality to play blank cards
  //TODO: Voting system (let all players vote for best card)
  //TODO: cardzar system (one person selects a card)
  //TODO: Import cards into questionbank
  //TODO: Update points if card i choosen
  //TODO: Fix new user system (when a new user enters a room)
  //TODO: Statuses. 0 = No game active, 1 = selecting cards, 2 = playing cards. (back and forth between 1-2 untill game end), 4 = game ended.

  useEffect(() => {
    db.ref(rootRef).on("value", (snapshot) => {
      if (snapshot.val()) {
        //if game is private
        if (snapshot.val().settings.private) {
          if (history.location.state && history.location.state.passwordTyped) {
            setAuthorized(true);
          }
        } else {
          setAuthorized(true);
        }
      } else {
        console.warn("NO GAME FOUND");
      }
    });
  }, [history, rootRef]);

  useEffect(() => {
    db.ref("cah/questionBank").on("value", (snapshot) => {
      if (snapshot.val()) {
        const sampleArray = _.toArray(snapshot.val());
        setQuestions(sampleArray);
      }
    });
  }, [rootRef]);

  useEffect(() => {
    db.ref(rootRef + "settings").on("value", (snapshot) => {
      if (snapshot.val()) {
        setgameSettings(snapshot.val());
      }
    });
  }, [id]);

  useEffect(() => {
    db.ref(rootRef + "players/" + userAuth.uid).on("value", (snapshot) => {
      if (snapshot.val()) {
        console.log("USER AVALIABLE");
        setThisPlayer(snapshot.val());
      } else {
        console.log("NO USER AVALIABLE");
        // createUserOnJoinRoom(id);
      }
    });
  }, [userAuth.uid]);

  useEffect(() => {
    db.ref(rootRef + "players/" + userAuth.uid + "/cards").on(
      "value",
      (snapshot) => {
        if (snapshot.val()) {
          console.log("snapshot.val", snapshot.val());
          const sampleArray = _.toArray(snapshot.val());
          console.log("sampleArray", sampleArray);
          setPlayerCards(snapshot.val());
        }
      }
    );
  }, [userAuth.uid]);

  useEffect(() => {
    db.ref(rootRef + "players").on("value", (snapshot) => {
      if (snapshot.val()) {
        const sampleArray = _.toArray(snapshot.val());
        setPlayers(sampleArray);
      }
    });
  }, [rootRef]);

  // const resetState = () => {
  //   setText("");
  // };

  const getRandomNumber = (max) => {
    let min = 0;

    return Math.floor(Math.random() * (max - min) + min);
  };

  const createUserOnJoinRoom = (roomCode) => {
    console.log("YEET?");

    db.ref(rootRef + roomCode + "/players/" + userAuth.uid).on(
      "value",
      (snapshot) => {
        console.log("SnAP", snapshot.val());
      }
    );

    let userData = {
      blank_cards_used: 0,
      uid: userAuth.uid,
      points: 0,
    };

    let name = prompt("Skriv inn navnet ditt");
    if (name === null || name === "") {
      console.warn("USER ENDED DIALOG");
      return;
    } else {
      userData.name = name;
    }

    let updates = {};
    updates[rootRef + "/players/" + userAuth.uid] = userData;

    return db.ref().update(updates);
  };

  const getRandomQuestion = () => {
    if (
      !gameSettings.allow_screen_tap &&
      userAuth.uid !== gameSettings.created_by
    ) {
      return;
    }
    if (questions.length > 0) {
      let number = getRandomNumber(questions.length);
      if (questions) {
        let question = questions[number].text;
        if (questions[number].type) {
          setRandomQuestionType(questions[number].type);
        } else {
          setRandomQuestionType("");
        }

        var containsPlayer = question.includes("@spiller");

        //If the question contain @spiller we have to replace with a random player
        if (containsPlayer) {
          let count = (question.match(/@spiller/g) || []).length;
          if (count > 1) {
            for (let i = 0; i < count; i++) {
              let randomPlayer = getRandomPlayer();
              question = _.replace(question, /@spiller/, randomPlayer.name);
            }
          } else {
            let randomPlayer = getRandomPlayer();
            question = _.replace(question, /@spiller/, randomPlayer.name);
          }
        }

        setRandomQuestion(question);
        getRandomColor();
      }
    } else {
      setRandomQuestion("Det finnes ingen spørsmål, legg til noen da vell 🥳");
    }
  };

  const getRandomColor = () => {
    setRandomColor("#" + (((1 << 24) * Math.random()) | 0).toString(16));
  };

  const getRandomPlayer = () => {
    return players[getRandomNumber(players.length)];
  };

  // const joinGameWithPassword = () => {
  //   if (joinGamePassword === gameSettings.password) {
  //     history.replace({
  //       state: { passwordTyped: true },
  //     });
  //     setAuthorized(true);
  //   }
  // };

  const changeEntrySetting = (e) => {
    let updates = {};

    if (e.target.name === "allow_entries") {
      updates[rootRef + "settings/allow_entries"] = !gameSettings.allow_entries;
    }

    if (e.target.name === "allow_new_players") {
      updates[rootRef + "settings/allow_new_players"] =
        !gameSettings.allow_new_players;
    }

    if (e.target.name === "allow_screen_tap") {
      updates[rootRef + "settings/allow_screen_tap"] =
        !gameSettings.allow_screen_tap;
    }

    return db.ref().update(updates);
  };

  const startGame = () => {
    console.log("starting game");
  };
  const endGame = () => {
    console.log("ending game");
  };

  const playCard = () => {
    console.log("PLAY", selectedCard);

    //Check if card actually exist
    db.ref(
      rootRef + "/players/" + userAuth.uid + "/cards/" + selectedCard.key
    ).on("value", (snapshot) => {
      console.log("CARD AT USER", snapshot.val());
    });

    // Write the new post's data simultaneously in the posts list and the user's post list.
    let updates = {};
    updates[rootRef + "selected_cards/" + userAuth.uid] = selectedCard.text;

    db.ref().update(updates);

    db.ref(
      rootRef + "/players/" + userAuth.uid + "/cards/" + selectedCard.key
    ).remove();

    //resetting selected card
    setSelectedCard({
      key: null,
      text: "",
    });
  };

  const selectCard = (text, key) => {
    setSelectedCard({
      key: key,
      text: text,
    });
  };

  return (
    <div className='App'>
      <section className='container-fluid'>
        {userAuth.uid === gameSettings.created_by ? (
          <div>
            <span className='col-12'>
              <h4>Instillinger</h4>
            </span>
            <div className='row'>
              <span className='col'>
                Poeng for å vinne : {gameSettings.win_points}
              </span>
              <span className='col'>
                Antall blanke kort : {gameSettings.blank_cards}
              </span>
              <span className='col'>
                {gameSettings.private ? (
                  <div>Passord: {gameSettings.password}</div>
                ) : null}
              </span>
              <span className='col'>
                {gameSettings.game_status === 0 ? (
                  <div>
                    <button
                      className='btn btn-primary btn-block'
                      onClick={startGame}
                    >
                      Start spill
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      className='btn btn-error btn-block'
                      onClick={endGame}
                    >
                      Avslutt spillet
                    </button>
                  </div>
                )}
              </span>
            </div>
          </div>
        ) : null}
        <div>
          <span className='col-12'>
            <h4>
              Romkode: {gameSettings.name}
              {gameSettings.password ? (
                <span> / Passord: {gameSettings.password}</span>
              ) : null}
            </h4>
          </span>
        </div>

        <div>
          <div className='col-2 cah-players'>
            <h5>Spillere</h5>

            <TransitionGroup
              transitionName='example'
              transitionEnterTimeout={300}
              transitionLeaveTimeout={300}
            >
              {players.map((player, i) => (
                <div key={i} className='cah-player row'>
                  <span className='col-8'>{player.name}</span>
                  <span className='col-4'>{player.points}</span>
                </div>
              ))}
            </TransitionGroup>
          </div>
          <div className='playArea col-10'></div>
        </div>

        {/* <div
          className="questionContainer"
          style={{ backgroundColor: randomColor }}
          onClick={getRandomQuestion}
        >
          <CSSTransitionGroup
            className="container text-center"
            transitionName="example"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
          >
            {questionHTML()}
          </CSSTransitionGroup>
        </div> */}

        <br></br>
        <div className=''>
          <div className='row'>
            <div className='col-12'>
              <TransitionGroup
                transitionName='example'
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
              >
                <div className='playerCards row'>
                  {playerCards.map((card, i) => (
                    <div className='col' key={i}>
                      <span
                        className={
                          selectedCard.key === i
                            ? "cah-card selected"
                            : "cah-card"
                        }
                        onClick={() => selectCard(card, i)}
                      >
                        {card} - {i}
                      </span>
                    </div>
                  ))}
                </div>
              </TransitionGroup>
              <button className='btn btn-primary btn-block' onClick={playCard}>
                Spill kort
              </button>
            </div>
          </div>
        </div>
        <br></br>
      </section>
    </div>
  );
}

export default CardsAgainstHumanity;
